<template>
    <Toast :baseZIndex="9999" />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>List Of Companies</h5>
            </div>
            <div class="card">
                <div style="position: relative" v-if="!showLoader">
                    <div class="p-py-4 p-px-3">
                        <div class="p-fluid p-formgrid p-grid">
                            <DataTable :value="companies" :lazy="true" :paginator="true" :rows="30"
                                :totalRecords="totalRecords" :loading="loading" @page="onPage($event)"
                                class="p-datatable-users" data-key="tf1" :rowHover="true"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                                <Toolbar>
                                    <template v-slot:right>
                                        <Button label="Add New Company" icon="pi pi-plus" @click="onAddCompany" />
                                    </template>
                                </Toolbar>

                                <template #empty> No data found. </template>

                                <template #loading> Loading data. Please wait... </template>
                                <Column header="Sr." headerStyle="width: 4%">
                                    <template #body="{ index }">
                                        <div>{{ genrateSrNo(index) }}</div>
                                    </template>
                                </Column>
                                <Column header="Company Name" field="tf2" headerStyle="width: 50%"></Column>

                                <Column header="Status" headerStyle="width: 20%">

                                    <template #body="{ data }">
                                        <div class="p-text-capitalize">
                                            <div v-if="data.tf3 == 1"> Active </div>
                                            <div v-else> Inactive</div>
                                        </div>
                                    </template>
                                </Column>
                                <Column header="Action" headerStyle="width: 10%">

                                    <template #body="{ data }">
                                        <Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2"
                                            @click="onEditCompany(data)" />
                                    </template>
                                </Column>
                            </DataTable>
                            <!-- <Toast /> -->
                        </div>
                    </div>
                </div>
                <div v-if="showLoader" style="height: 390px; position: relative">
                    <div class="custom-modal-spinner-loader">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Add/Edit Company Dialog starts here -->
    <Dialog v-model:visible="isDialogOpen" :style="{ width: '800px' }" :modal="true" class="p-fluid"
        :header="dialogHeader">
        <div style="position: relative">
            <div class="p-pt-2 p-px-3">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-4">
                        <label for="companyName">
                            Company Name
                            <span class="p-invalid p-error">*</span>
                        </label>
                        <InputText id="companyName" v-model.trim="companyName" required="true" maxlength="50" autofocus
                            :class="{ 'p-invalid': isSubmitted && !companyName }" />
                        <small class="p-invalid p-error" v-if="v$.companyName.$error">{{
        v$.companyName.$errors[0].$message }}</small>
                    </div>
                    <div class="p-field p-col-12 p-md-2">
                        <label>Status <span class="p-invalid p-error"> *</span></label>
                        <div class="p-field-radiobutton">
                            <RadioButton id="status_active" name="option" v-bind:value="1" v-model="statusType"
                                :checked="statusType == 1" />
                            <label for="status_active" class="p-mr-2">Active</label>
                            <RadioButton id="status_Inactive" name="option" v-bind:value="0" v-model="statusType"
                                :checked="statusType == 0" />
                            <label for="status_Inactive" class="p-mr-2">Inactive</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template #footer>
            <Button label="Submit" :icon="!showLoader ? 'pi pi-check' : 'pi pi-spin pi-spinner indigo-color'"
                class="p-button-text p-ml-auto" @click="addCompany" style="width: 90px;"></Button>
            <!-- <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                style="width: 90px"></Button> -->
        </template>
    </Dialog>
    <!-- Add/Edit Company Dialog ends here -->
</template>

<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';

export default {
    data() {
        return {
            companies: [],
            v$: useValidate(),
            isDialogOpen: false,
            isSubmitted: false,
            showLoader: false,
            loading: false,
            page_no: 0,
            totalRecords: 0,
            companyName: '',
            statusType: 0,
            companyId: 0,
            dialogHeader: "",
        };
    },
    validations() {
        return {
            companyName: { required: helpers.withMessage('Please enter company name', required) },
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.getCompaniesList();
    },
    methods: {

        onAddCompany() {
            this.v$.$reset();
            this.xyz = "";
            /** Open popup of add Company gode goes here */
            this.dialogHeader = 'Add company';
            this.isDialogOpen = true;
            this.companyName = '';
        },
        onEditCompany(data) {
            this.dialogHeader = "Edit company";
            this.isDialogOpen = true;
            this.companyId = data.tf1;
            this.companyName = data.tf2;
            this.statusType = data.tf3;
        },
        async getCompaniesList(params) {
            try {
                const result = await this.ApiService.getCompaniesList({ ...params })
                this.loading = false;
                if (!result.success) {
                    return this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong.', life: 3000 });
                }
                this.companies = result.data;
                this.totalRecords = result.count;
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong.', life: 3000 });

            }
        },
        async addCompany() {
            this.isSubmitted = true;
            this.v$.$validate();
            /** Add Company code goes here */
            // const formData = new FormData();
            if (this.v$.error) return;
            let formData = new FormData();
            formData.append("tf1", this.companyId);
            formData.append("tf2", this.companyName);
            formData.append("tf3", this.statusType);
            if (!this.v$.$error) {
                this.loading = this.showLoader = true;
                let result = await this.ApiService.createUpdateCompanies(formData)
                this.loading = this.showLoader = this.isDialogOpen = false;
                if (result.success) {
                    this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Company added successfully', life: 3000 });
                    this.getCompaniesList();
                    this.CompanyId = 0;
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Company not added. Please try again', life: 3000 });

                }
            }
        },
    }

};
</script>

<style scoped></style>
